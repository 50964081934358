<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 whole_fff flex_bd">
				<div class="order_tab">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="$t('ldtab1')" name="first">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="id" label="#">
									</el-table-column>
									<el-table-column prop="coinUnit" :label="$t('wtth3')">
									</el-table-column>
									<el-table-column prop="num" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column prop="duration" :label="$t('reth2')">
									</el-table-column>
									<el-table-column prop="rate" :label="$t('reth1')">
										<template slot-scope="scope">
											<div class="bi-text bi-green">
												{{scope.row.rate}}%
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="startTime" :label="$t('ldth1')">
									</el-table-column>
									<el-table-column prop="endTime" :label="$t('ldth2')">
									</el-table-column>
									<el-table-column prop="earnNum" :label="$t('ldth3')">
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
								<div class="exte-box">
									<el-pagination @current-change="handleCurrentChange"
										:current-page.sync="currentPage" layout="prev, pager, next" :total="total"
										:background="true"></el-pagination>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="$t('ldtab2')" name="second">
							<div class="order-table">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column prop="id" label="#">
									</el-table-column>
									<el-table-column prop="coinUnit" :label="$t('wtth3')">
									</el-table-column>
									<el-table-column prop="num" :label="$t('wtth4')">
									</el-table-column>
									<el-table-column prop="duration" :label="$t('reth2')">
									</el-table-column>
									<el-table-column prop="rate" :label="$t('reth1')">
									</el-table-column>
									<el-table-column prop="startTime" :label="$t('ldth1')">
									</el-table-column>
									<el-table-column prop="endTime" :label="$t('ldth2')">
									</el-table-column>
									<el-table-column prop="earnNum" :label="$t('ldth4')">
									</el-table-column>
									<template slot="empty" class="exte_empty">
										<img src="../../assets/photo/exte1.png" alt="">
										<p>{{$t('czEmpty')}}</p>
									</template>
								</el-table>
								<div class="exte-box">
									<el-pagination @current-change="handleCurrentChange2"
										:current-page.sync="currentPage2" layout="prev, pager, next" :total="total2"
										:background="true"></el-pagination>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		goingOrder,
		doneOrder
	} from '@/api/api/earn'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				input: '',
				activeName: 'first',
				tableData: [],
				tableData2: [],
				currentPage: 1,
				pageSize: 10,
				total: 10,
				currentPage2: 1,
				pageSize2: 10,
				total2: 10
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				goingOrder({
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code == 0) {
						var arr = []
						res.data.content.forEach(item => {
							item.rate = this.$math.format(
								this.$math.multiply(
									this.$math.bignumber(100), this.$math.bignumber(item.rate)
								)
							)
							return arr.push(item)
						})
						this.tableData = arr
						this.total = res.data.totalElements
						this.pageSize = res.data.size
					}
				})
			},
			getList2() {
				doneOrder({
					pageNo: this.currentPage2,
					pageSize: this.pageSize2
				}).then(res => {
					if (res.code == 0) {
						var arr = []
						res.data.content.forEach(item => {
							item.rate = this.$math.format(
								this.$math.multiply(
									this.$math.bignumber(100), this.$math.bignumber(item.rate)
								)
							)
							return arr.push(item)
						})
						this.tableData2 = arr
						this.total2 = res.data.totalElements
						this.pageSize2 = res.data.size
					}
				})
			},
			handleClick(tab) {
				if (tab.name == "second") {
					this.getList2()
				}
				if (tab.name == "first") {
					this.getList()
				}
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			handleCurrentChange2(val) {
				this.currentPage2 = val
				this.getList2()
			}
		}
	}
</script>
